export async function renderInsights($el) {
    const url = $el.dataset.insightsUrl
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    const insights = await response.json();
    import('chart.js').then((module) => {
        module.Chart.register(...module.registerables);
        const makeChart = function ($el, data, title, label, type = 'bar') {
            new module.Chart($el, {
                    type: type,
                    data: {
                        labels: data.labels,
                        datasets: [
                            {
                                data: data.values,
                                label: label,
                                backgroundColor: '#a8eae5',
                                borderColor: '#a8eae5',
                            }
                        ],
                    },
                    options: {
                        plugins: {
                            title: {
                                display: true,
                                text: title
                            },
                        },
                        responsive: true,
                        interaction: {
                            intersect: false,
                        },
                    }
                }
            );
        }
        new module.Chart($el.querySelector('#nodeChartContext'), {
            type: 'bar',
            data: {
                labels: insights.node_dist.labels,
                datasets: [
                    {
                        data: insights.node_dist.sources,
                        label: 'Sources',
                        backgroundColor: '#a8eae5',
                    },
                    {
                        data: insights.node_dist.targets,
                        label: 'Targets',
                        backgroundColor: '#30c9bc',
                    }
                ],
            },
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Sources/targets distribution along sequence'
                    },
                },
                responsive: true,
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            precision: 2
                        },
                    },
                    y: {
                        beginAtZero: true,
                        stacked: true
                    },
                },
            }
        });
        makeChart(
            $el.querySelector('#tmChartContext'),
            insights.tm_dist,
            'Tm distribution',
            'Tm'
        )
        makeChart(
            $el.querySelector('#gcChartContext'),
            insights.gc_dist,
            'GC distribution',
            'GC'
        )
        makeChart(
            $el.querySelector('#deltaGChartContext'),
            insights.delta_g_dist,
            'Delta G distribution',
            'Delta G'
        )
    })
}