const {initBootstrap, loadComponents} = require("../bootstrap");
window.htmx = require('htmx.org');

// https://htmx.org/extensions/ajax-header/
htmx.defineExtension('ajax-header', {
    onEvent: function (name, evt) {
        if (name === "htmx:configRequest") {
            evt.detail.headers['X-Requested-With'] = 'XMLHttpRequest';
        }
    }
});

// https://htmx.org/docs/#request-operations
htmx.on('htmx:afterSettle', function (elt) {
    const element = elt.target;
    initBootstrap(element);
    loadComponents(element);
})

htmx.on("htmx:configRequest", function (event) {
    let pathWithParameters = event.detail.path.replace(/:([A-Za-z0-9_]+)/g, function (_match, parameterName) {
        let parameterValue = event.detail.parameters[parameterName]
        delete event.detail.parameters[parameterName]

        return parameterValue
    })

    // prevent invalid urls when parameterValue is empty
    event.detail.path = pathWithParameters.replaceAll('//', '/')
})