/**
 *
 * Reference
 * ---------
 * https://github.com/NicolasCARPi/dropzone
 *
 * **/

import {appendSpinner} from "../utils";
import {csrfToken} from "./axios";

const DJANGO_FILE_FIELD_NAME = 'upload'

async function sha256(file) {
    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

function preventDefault(e) {
    e.preventDefault()
}


export function renderDropzone($elem) {
    Promise.all([
        import('@deltablot/dropzone/dist/dropzone.css'),
        import('@deltablot/dropzone'),
    ]).then(([_, module]) => {
        const $modal = document.getElementById('modal')
        const submitButton = $elem.querySelector('[type="submit"]')
        new module.Dropzone($elem, {
            paramName: (n) => DJANGO_FILE_FIELD_NAME, // make dropzone compliant with Django
            url: $elem.action || window.location.href,
            uploadMultiple: false,
            autoProcessQueue: false,
            addRemoveLinks: true,
            parallelUploads: 10,
            maxFiles: 10,
            maxFilesize: 256,
            chunking: true,
            forceChunking: true,
            headers: {
                'X-CSRFTOKEN': csrfToken
            },
            init: function () {
                $elem.addEventListener('submit', (e) => {
                    e.preventDefault()
                    let queuedFiles = this.getQueuedFiles();
                    if (queuedFiles.length > 0) {

                        // Ask user for confirmation upon leaving the page and prevent modal closing
                        window.addEventListener('beforeunload', preventDefault)
                        $modal.addEventListener('hide.bs.modal', preventDefault)
                        submitButton.disabled = true

                        this.processQueue()

                        // if at least one file has been successfully uploaded then refresh the page when modal has been closed
                        this.on('success', file => {
                            $modal.addEventListener('hidden.bs.modal', () => window.location.reload())
                        })

                        this.on('queuecomplete', () => {
                            window.removeEventListener('beforeunload', preventDefault)
                            $modal.removeEventListener('hide.bs.modal', preventDefault)
                            submitButton.disabled = false
                        })
                    }
                })
            },
            params: function (files, xhr, chunk) {
                if (chunk) {
                    return {
                        upload_id: chunk.file.upload.uuid,
                        chunk_index: chunk.index,
                        total_chunks: chunk.file.upload.totalChunkCount,
                    };
                }
            },
            chunksUploaded: async function (file, done) {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", "/api/files/" + file.upload.uuid + '/complete/', true);
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader("X-CSRFToken", csrfToken);
                xhr.send(JSON.stringify({checksum: await sha256(file)}));
                done()
            },
            // https://docs.dropzone.dev/misc/tips
            previewsContainer: '.previews',
        });
    })
}
