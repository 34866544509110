import {Popover} from "bootstrap";

const Selector = '.copy-to-clipboard';

export function initClipboard(container = null) {
    import('clipboard').then((module) => {
        const clipboard = new module.default(Selector, {
            text: (trigger) => {
                if (!trigger.dataset.clipboardText) {
                    // TODO reimplement this: return the text you want to be copied
                    let contentClipboard = '';
                    const contentSeparator = trigger.dataset.clipboardSeparator;
                    const sources = document.querySelectorAll(`[data-clipboard-source="${trigger.id}"]`)
                    sources.forEach((item) => {
                        const itemsContent = item.querySelectorAll('[data-clipboard-content]')
                        itemsContent.forEach((trigger, i) => {
                            contentClipboard += trigger.dataset.clipboardContent;
                            contentClipboard += i < (itemsContent.length - 1) ? contentSeparator : "";
                        })
                        contentClipboard += "\r\n"
                    });
                    trigger.dataset.clipboardText = contentClipboard;
                }
                return trigger.dataset.clipboardText
            },
            container: container || document.body
        });

        clipboard.on('success', (e) => {
            let $popover = Popover.getOrCreateInstance(e.trigger, {
                content: "Copied!",
                placement: "top",
                trigger: "manual",
            })
            e.trigger.addEventListener('shown.bs.popover', () => {
                setTimeout(() => {
                    if ($popover) {
                        $popover.dispose()
                        $popover = null
                    }
                    },
                    1000
                )
            })
            $popover.show();
        })


    });
}
