export function validateCodingSequence(startEl, endEl) {
    let maxLength = parseInt(startEl.max),
        startVal = parseInt(startEl.value),
        endVal = parseInt(endEl.value);
    if (endVal < startVal)
        endVal += maxLength;
    let diff = endVal - startVal + 1,
        isValid = diff % 3 === 0;
    startEl.classList.toggle('is-invalid', !isValid);
    startEl.classList.toggle('is-valid', isValid);
    endEl.classList.toggle('is-invalid', !isValid);
    endEl.classList.toggle('is-valid', isValid);
    return isValid;
}
