export function appendSpinner(el) {
    if (el.form) {
        el.form.classList.add('busy')
        el.classList.add('disabled-when-busy')
    }
    let spinner = el.querySelector('.spinner')
    if (!spinner) {
        el.style.minWidth = el.offsetWidth + 'px';
        el.innerHTML = `<span class="d-none-busy">${el.innerHTML}</span>`;
        spinner = document.createElement('div')
        spinner.className = 'align-middle spinner-border spinner-border-sm d-busy mx-auto'
        el.append(spinner)
    }
    return spinner;
}
