import * as bootstrap from "bootstrap";

import {initClipboard} from "./modules/clipboard";
import {renderDropzone, renderInsights, validateCodingSequence} from "./utils";
import {storeSelection} from "./modules/select";

const Selector = {
    POPOVER: '[data-bs-toggle="popover"]',
    VIRTUAL_POPPER: '[data-toggle="virtual-popper"]',
    TOOLTIP: '[data-bs-tooltip]',
    TOAST_ID: 'toast',
    TOAST_BODY: '.toast-body',
    TOM_SELECT: 'select[multiple], [data-tom-select]',
    SEQUENCE: '.sequence textarea',
    PROTOCOL_GRID: '[data-protocol-grid]',
    DATE: '.dateinput',
    DATE_TIME: '.isodatetimeinput',
    FIRST_TEXT_INPUT: '.modal-dialog textarea, .modal-dialog input[type="text"]:not([readonly])',
    CODING_SEQUENCE_FORM_START: '.coding-sequence-form #id_start',
    CODING_SEQUENCE_FORM_END: '.coding-sequence-form #id_end',
    DROPZONE: '.dropzone',
    INSIGHTS: '[data-insights-url]',
    STORAGE_FORM: 'form[data-storage-id]',
};

const SeverityToColor = {
    0: '#47d1b1',
    1: '#F3C96C',
    2: '#DA3236'
};


export function initBootstrap(element = document.body, config = {
    popover: true,
    tooltip: true,
}) {

    if (config.popover) {
        const shownPopovers = document.querySelectorAll('.popover.show')
        shownPopovers.forEach((el) => el.remove())
        // https://getbootstrap.com/docs/5.3/components/popovers/#enable-popovers
        const popoverTriggerList = document.querySelectorAll(Selector.POPOVER);
        [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
    }

    if (config.tooltip) {
        // https://getbootstrap.com/docs/5.3/components/popovers/#enable-popovers
        const tooltipTriggerList = [].slice.call(element.querySelectorAll(Selector.TOOLTIP));
        [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
    }
}

export function appendToast(
    $container,
    message,
    severity = 0
) {
    const severityColor = SeverityToColor[severity]
    const toastTemplate = document.getElementById(Selector.TOAST_ID)
    const toastEl = toastTemplate.content.cloneNode(true);
    const toastBody = toastEl.querySelector(Selector.TOAST_BODY)
    const toastRect = toastEl.querySelector('rect')
    toastRect.style.fill = severityColor
    toastBody.innerHTML = message
    $container.appendChild(toastEl)
    const toast = new bootstrap.Toast($container.lastElementChild)
    toast.show()
}


export function loadComponents(element = document.body) {
    let startEl = element.querySelector(Selector.CODING_SEQUENCE_FORM_START)
    let endEl = element.querySelector(Selector.CODING_SEQUENCE_FORM_END)
    if (startEl && endEl)
        validateCodingSequence(startEl, endEl)

    initClipboard(element)
    // Enable tom-select for select elements
    element.querySelectorAll(Selector.TOM_SELECT).forEach((el) => {
        let optionsCount = el.querySelectorAll('option').length;
        if (optionsCount > 10 || el.dataset.tomSelect !== undefined) {
            import('tom-select').then((module) => {
                const settings = {
                    plugins: ['remove_button'],
                    onItemAdd: function () {
                        this.setTextboxValue('');
                        this.refreshOptions();
                    },
                    createOnBlur: el.dataset.tomSelectCreate !== undefined,
                    create: el.dataset.tomSelectCreate !== undefined,
                    addPrecedence: el.dataset.tomSelectAddPrecedence !== undefined,
                    preload: el.dataset.tomSelectPreload !== undefined ? 'focus' : false,
                    maxItems: el.dataset.tomSelectMaxItems !== undefined ? el.dataset.tomSelectMaxItems : null,
                }
                if (el.dataset.tomSelectUrl) {
                    settings.labelField = el.dataset.tomSelectLabelField || 'name'
                    settings.valueField = el.dataset.tomSelectLabelField || 'name'
                    settings.searchField = el.dataset.tomSelectLabelField || 'name'
                    settings.load = function (query, callback) {
                        const url = `${el.dataset.tomSelectUrl}?search=${encodeURIComponent(query)}`
                        fetch(url)
                            .then(response => response.json())
                            .then(json => {
                                callback(json.results);
                            }).catch(() => {
                            callback();
                        });
                    }
                }
                if (el.dataset.tomSelectRender === 'colors') {
                    settings.render = {
                        option: function (item, escape) {
                            return `
                                <div class="d-flex p-2">
                                    <button class="btn ${item.value} btn-sm rounded-4 px-3">${escape(item.text)}</button>                          
                                </div>
                            `;
                        },
                        item: function (item, escape) {
                            return `
                                <div class="d-flex">
                                    <button class="btn ${item.value} btn-sm rounded-4 px-3">${escape(item.text)}</button>                          
                                </div>
                            `;
                        },
                    }
                }
                const control = new module.default(el, settings);
                if (el.dataset.tomSelectUrl) {
                    fetch(el.dataset.tomSelectUrl)
                        .then(response => response.json())
                        .then(json => {
                            control.addOptions(json.results)
                        })
                }
            })
        }
    });

    // Enable flatpickr for date elements
    element.querySelectorAll(Selector.DATE).forEach((el) => {
        Promise.all([
            import('flatpickr/dist/themes/light.css'),
            import('flatpickr'),
        ]).then(([_, module]) => {
            module.default(el, {
                altInput: true,
                enableTime: false,
                altFormat: "F j, Y",
            })
        })
    })

    // Enable flatpickr for date-time elements
    element.querySelectorAll(Selector.DATE_TIME).forEach((el) => {
        Promise.all([
            import('flatpickr/dist/themes/light.css'),
            import('flatpickr'),
        ]).then(([_, module]) => {
            module.default(el, {
                altInput: true,
                enableTime: true,
            })
        })
    })

    // Render plate
    element.querySelectorAll(Selector.PROTOCOL_GRID).forEach((el) => {
        import('./modules/plate').then((plate) => {
            const protocolID = el.dataset.protocolId || 'default';
            const labels = el.dataset.plateLabels || false;
            plate.fillPlate(protocolID, el, el.dataset.protocolGrid, labels);
        })
    });

    // Handle textarea form sequence field
    const $sequence = element.querySelector(Selector.SEQUENCE)
    if ($sequence)
        import('./modules/sequence').then((e) => {
            e.default($sequence)
        })

    const $dropzone = element.querySelector(Selector.DROPZONE)
    if ($dropzone) {
        renderDropzone($dropzone)
    }

    const $insights = element.querySelector(Selector.INSIGHTS)
    if ($insights) {
        renderInsights($insights)
    }

    const $storedForms = element.querySelectorAll(Selector.STORAGE_FORM)
    storeSelection($storedForms)

}
